"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("h3", {
    staticStyle: {
      "margin-bottom": "10px",
      "margin-top": "10px"
    }
  }, [_vm._v("Latest Agro News")]), _c("div", {
    staticClass: "dashboard-executive-container"
  }, [_c("div", {
    staticClass: "panel-group"
  }, _vm._l(_vm.blogData, function (element) {
    return _c("div", {
      key: element.id,
      staticClass: "panel-card"
    }, [_c("a", {
      attrs: {
        href: element.source
      }
    }, [_c("div", {
      staticClass: "home-categories-cards",
      staticStyle: {
        "margin-top": "0px"
      }
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "feature-section-v2-list text-center home-categories-section",
      staticStyle: {
        "padding-top": "0px"
      }
    }, [_c("img", {
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        src: element.cover
      }
    }), element.title ? _c("h4", {
      staticStyle: {
        "margin-top": "2px",
        "margin-bottom": "5px",
        "text-align": "left",
        width: "100%"
      }
    }, [_c("b", [_vm._v(_vm._s(element.title))])]) : _vm._e(), element.summary ? _c("h5", {
      staticStyle: {
        "margin-top": "2px",
        "margin-bottom": "5px",
        "text-align": "left",
        width: "100%"
      }
    }, [_vm._v(" " + _vm._s(element.summary) + " ")]) : _vm._e(), _c("link", {
      attrs: {
        href: "https://fonts.googleapis.com/icon?family=Material+Icons",
        rel: "stylesheet"
      }
    }), _c("div", {
      staticClass: "blog-meta d-flex align-items-center justify-content-between"
    }, [_c("div", {
      staticClass: "d-flex align-items-center",
      staticStyle: {
        "margin-top": "5px",
        "margin-bottom": "5px"
      }
    }, [_c("i", {
      staticClass: "material-icons mr-2"
    }, [_vm._v("account_circle")]), _c("span", {
      staticClass: "font-weight-regular"
    }, [_vm._v(_vm._s(element.sourceName))])]), _c("div", {
      staticClass: "d-flex align-items-center"
    }, [_c("i", {
      staticClass: "material-icons mr-2"
    }, [_vm._v("date_range")]), _c("span", {
      staticClass: "font-weight-regular"
    }, [_vm._v(_vm._s(element.createdTimestamp))])])]), _c("div", {
      staticClass: "py-2"
    }, [_c("v-btn", {
      staticClass: "accent",
      attrs: {
        href: _vm.blogData.source,
        target: "_blank"
      }
    }, [_vm._v("Read More")])], 1)])])])])]);
  }), 0)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;